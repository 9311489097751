<template>
    <v-form @submit.prevent="submit" @keyup.enter.prevent="submit">
        <template v-if="!editMode">
            <v-row no-gutters align="center">
                <slot name="view"><span v-if="label">{{ label }}:&nbsp;</span>{{ value }}</slot>
                <slot name="button">
                    <v-btn icon color="green darken-2" @click="editMode = true">
                        <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                    </v-btn>
                </slot>
                <slot name="view--after-mode-buttons"></slot>
            </v-row>
        </template>
        <template v-if="editMode">
            <v-row no-gutters align="center">
                <slot name="input">
                    <v-text-field v-model="editableText" :label="label" :hint="hint" :dense="dense" ref="editableTextInput"></v-text-field>
                </slot>
                <slot name="submit">
                    <v-btn icon color="green darken-2" @click="submit">
                        <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon>
                    </v-btn>
                    <v-btn icon color="grey darken-2" @click="cancel">
                        <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                    </v-btn>
                </slot>
                <slot name="edit--after-mode-buttons"></slot>
            </v-row>
        </template>
    </v-form>
</template>

<script>
export default {
    props: ['value', 'dense', 'label', 'hint'],
    data: () => ({
        editMode: false,
        editableText: null,
        submitFormTimestamp: null,
    }),
    computed: {
    },
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.editableText = this.value;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableTextInput'); }, 1);
                });
            }
        },
    },
    methods: {
        submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            this.$emit('input', this.editableText);
            this.cancel();
        },
        cancel() {
            this.editMode = false;
            this.editableText = null;
        },
    },
};
</script>
