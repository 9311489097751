<template>
    <v-row no-gutters>
        <v-col cols="12">
            <ProductBar :organizationId="$route.params.organizationId" :productGroup="productGroup" class="mb-6" v-if="productGroup"></ProductBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link> &gt;
                    <router-link :to="{ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } }">Products</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="productGroup">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end grey--text text--darken-2 mb-0">Product Group {{ productGroup.id }}</p>
                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>
                                Product Group
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="text-caption mb-0 pb-0 mt-0">The following product group information is PUBLIC and is shown to customers.</p>
                        </v-card-text>
                        <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 100px;">Name</th>
                                        <td style="width: 100%;">
                                            <EditableText :value="productGroup.label" @input="saveProductGroupLabel" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Reference</th>
                                        <td>
                                            <EditableText :value="productGroup.ref" @input="saveProductGroupRef" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Alias</th>
                                        <td>
                                            <EditableText :value="productGroup.alias" @input="saveProductGroupAlias" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td>
                                            <EditableTextSelect :value="productGroup.type" :items="productTypeChoices" @input="saveProductGroupType" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Description</th>
                                        <td>
                                            <EditableText :value="productGroup.description" @input="saveProductGroupDescription" dense/>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                            <!-- TODO: enter flat rate shipping (when using USPS) or set up custom rate shipping integration for FedEx, UPS, etc. based on package size and weight and the carrier... various services exist that help with the computation, or we can do our own query of the current rates and compute for the merchant... -->

                            <!-- TODO: move to comment component -->
                            <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                            <p class="text-caption mb-0 pb-0 mt-0">The comment is PRIVATE and is NOT shown to customers.</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="product.comment" @input="saveProductComment" dense/>
                            </p> -->
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewBrandProfileURL" target="_blank">{{ viewBrandProfileURL }}</TextLink>
                            </p> -->

                        <v-card-text>
                            <!-- TODO: add existing product to group from here -->
                            <p class="text-overline mb-0 mt-0">
                                Products in this group
                            </p>
                            <v-list v-if="Array.isArray(productList)">
                                <v-list-item v-for="product in productList" :key="product.id">
                                    <v-list-item-content>
                                        <!-- <EditableProductPrice :value="item" :product="product" @input="saveProductPrice" @delete="deleteProductPrice"/> -->
                                        <v-list-item-title>{{ product.name }}</v-list-item-title>
                                        <v-list-item-subtitle><router-link :to="{ name: 'organization-edit-product', params: { organizationId: $route.params.organizationId }, query: { id: product.id, t: Date.now() } }">Edit</router-link></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <!-- <v-card flat tile color="white">
                                <v-toolbar dense flat color="white">
                                    <v-toolbar-title></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon color="green darken-2">
                                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                                    </v-btn>
                                </v-toolbar>
                            </v-card> -->
                            <!-- TODO: there should be a constants file so we can have PRODUCT_TYPE_SAAS here so it's clearly a constant that has to match the server's constant, and not an arbitrary UI value -->
                            <template v-if="productGroup.type === 'saas' && Array.isArray(productList)">
                                <!-- TODO: (status: in progress) drag and drop to arrange products in good-better-best upgrade order -->
                                <!-- <p class="mb-0 mt-0">
                                    Upgrade order from least expensive to most expensive:
                                </p>
                                <draggable v-model="sortableProductList" group="items" @start="drag=true" @end="drag=false">
                                <v-chip small v-for="product in sortableProductList" :key="product.id" class="mx-1">
                                    {{ product.name }}
                                </v-chip>
                                </draggable>
                                <pre>{{ JSON.stringify(sortableProductList, null, 2) }}</pre> -->
                            </template>
                        </v-card-text>
                    </v-card>
                    <!-- <v-dialog v-model="addPriceDialog" max-width="600">
                    <v-card class="pa-0" max-width="600">
                        <v-toolbar short flat color="green lighten-3">
                            <v-toolbar-title >Add Price</v-toolbar-title>
                        </v-toolbar>
                        <v-form @submit.prevent="createPrice" @keyup.enter.native.prevent="createPrice" class="px-2">
                            <v-text-field v-model="newItemLabel" label="Label" hint="A label for this client token; for example which application or server is using it" ref="newItemLabelInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createPrice" :disabled="!isCreatePriceFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="addPriceDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import draggable from 'vuedraggable';
import EditableText from '@/components/EditableText.vue';
import EditableTextSelect from '@/components/EditableTextSelect.vue';
// import EditableProductPrice from '@/components/EditableProductPrice.vue';
import ProductBar from '@/components/ProductBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
// import EditableProductSaasContent from '@/components/EditableProductSaasContent.vue';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // draggable,
        // TextLink,
        EditableText,
        EditableTextSelect,
        // EditableProductPrice,
        ProductBar,
        AccessDeniedOverlay,
        // EditableProductSaasContent,
    },
    data: () => ({
        organization: null,
        productGroup: null,
        productList: null, // all products with this product_group_id, unordered
        sortableProductList: [], // products in "upgrade order" specified by admin
        status: null,
        error: null,
        forbiddenError: false,

        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitFormTimestamp: null,

        addPriceDialog: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            productTypeChoices: (state) => state.productTypeChoices,
            productPublishedChoices: (state) => state.productPublishedChoices,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isCreatePriceFormComplete() {
            return false;
        },
        // customerProductLink() {
        //     // TODO: get default site with possible custom hostname...  and the brandprofile, if needd...
        //     return "";http://customer.unicornsprings.test/brand/libertydns/product?id=06J5B62A4Z7WRCCBET4G
        // },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadProductGroup() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProductGroup: true });
                const query = { id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).productGroup.get(query);
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (response?.item) {
                    this.productGroup = response.item;
                    this.sortableProductList = response.item.content?.upgrade_order ?? [];
                    this.checkProductGroup();
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load product', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProductGroup: false });
            }
        },
        async checkProductGroup() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkProductGroup: true });
                const query = { id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).productGroup.check(query, { item: 'product_type' }); // check that all linked products are of the same type as the product group
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.status = response;
                } else {
                    this.status = null;
                }
            } catch (err) {
                console.error('failed to check product status', err);
                this.status = null;
                this.error = true;
            } finally {
                this.$store.commit('loading', { checkProductGroup: false });
            }
        },
        async loadProductList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProductList: true });
                const query = { product_group_id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).product.search(query);
                console.log(`editproduct.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.productList = response.list;
                    // TODO: sort this according to this.sortableProductList
                } else {
                    this.productList = [];
                }
            } catch (err) {
                console.error('failed to load prices', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProductList: false });
            }
        },
        async saveProductGroupAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).productGroup.edit({ id: this.$route.query.id }, { [name]: value });
                console.log(`saveProductAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.productGroup, name, value);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit product group' });
                }
            } catch (err) {
                console.error(`failed to edit product group attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit product group' });
            } finally {
                this.$store.commit('loading', { saveProductAttr: false });
            }
        },
        async saveProductGroupLabel(label) {
            this.saveProductGroupAttr('label', label);
        },
        async saveProductGroupRef(ref) {
            this.saveProductGroupAttr('ref', ref);
        },
        async saveProductGroupAlias(alias) {
            this.saveProductGroupAttr('alias', alias);
        },
        async saveProductGroupType(type) {
            await this.saveProductGroupAttr('type', type);
            await this.loadProductList(); // server may have changed some prices to draft if they don't apply to the new product type
        },
        async saveProductGroupDescription(description) {
            this.saveProductGroupAttr('description', description);
        },
        /**
         * The type-specific content for a product is stored in the product
         * record as content[type], so that if the user switch the type of an
         * object and then decides to switch it back, the type-specific
         * information is not lost, while also not being mixed up with the
         * other type-specific information. The server may decide to remove
         * all irrelevant (not for the current type) type-specific information
         * when the draft is published.
         */
        async saveProductGroupContentByType(value) {
            const content = this.productGroup.content ?? {};
            const type = this.productGroup.type; // must not be null
            this.saveProductGroupAttr('content', { ...content, [type]: value });
        },
        // TODO: it would be nice to be able to add a product to the group from here... search products of the same type, select it, then add it to the group (if it already has a group mention what it is and confirm user wants to replace it)
    },
    mounted() {
        this.loadOrganization();
        this.loadProductGroup();
        this.loadProductList();
    },
};
</script>
